import { useCallback, useEffect, useMemo } from "react";
import { ReloadIcon, cn } from "@androshq/shared-ui";
import {
  ColDef,
  ColGroupDef,
  ColumnVisibleEvent,
  GridApi,
  GridReadyEvent,
  GroupCellRendererParams,
  RowClickedEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import { format, subDays } from "date-fns";
import { differenceInDays } from "date-fns";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { getDisplayNameForIdentity, getVendorApprovalStatus } from "../../api/standards";
import { Company, DAYS_CARGO_READY_TO_SHIP, ItemWithConfig, State } from "../../api/types";
import ItemNameCell from "../../components/tables/cells/ItemNameCell";
import LinkCell from "../../components/tables/cells/LinkCell";
import { dateFormatShort } from "../../constants";
import { formatCellDate, formatDateFromNowByMin, formatDeltaNumeric, getDateObj } from "../../utils";
import { buildSourceLink } from "../items/ItemSupplyPlanningPage/helpers";
import { CAWorkflowExecution } from "./changeApprovals/types";
import WFExecIconStatus from "./components/WFExecIconStatus";
import {
  SYNC_LINES_TOOLTIP_MSG_ACTIVE,
  SYNC_LINES_TOOLTIP_MSG_DISABLED_INVALID_SELECTION,
  SYNC_LINES_TOOLTIP_MSG_DISABLED_NONE_SELECTED,
} from "./messages";

interface Props {
  rowData: CAWorkflowExecution[];
  gridApi: GridApi<CAWorkflowExecution> | null;
  setGridApi: (arg: GridApi) => void;
  setIsPossibleSyncData: ({ isPossible, reason }: { isPossible: boolean; reason: string }) => void;
  isLoading: boolean;
  companies: Company[];
  items: ItemWithConfig[];
  vendors: State[];
  isSyncing: boolean;
}

const STANDARD_WIDTHS = {
  LG: { width: 140 },
  MD: { width: 105, maxWidth: 115 },
  SM: { width: 70, maxWidth: 70 },
};

export default function WorkflowExecutionsTable({
  rowData,
  gridApi,
  setGridApi,
  setIsPossibleSyncData,
  isLoading,
  companies,
  items,
  vendors,
  isSyncing,
}: Props) {
  const navigate = useNavigate();

  const location = useLocation();
  const columnDefs: (ColGroupDef<CAWorkflowExecution> | ColDef<CAWorkflowExecution>)[] = useMemo(
    () => [
      {
        headerName: "",
        suppressColumnsToolPanel: true,
        children: [
          {
            headerName: "",
            field: "checkbox",
            checkboxSelection: true,
            maxWidth: 35,
          },
        ],
      },
      {
        headerName: "Workflow Execution",
        children: [
          {
            field: "workflow_type_name",
            headerName: "Workflow Type",
            ...STANDARD_WIDTHS.LG,
            valueGetter: (params: ValueGetterParams<CAWorkflowExecution, string>) => params.data?.workflow_type_name,
          },
          {
            field: "start_time",
            headerName: "Start Time",
            ...STANDARD_WIDTHS.MD,

            valueGetter: (params: ValueGetterParams<CAWorkflowExecution, string>) => params.data?.start_time,
            valueFormatter: (params: ValueFormatterParams<CAWorkflowExecution, string>) =>
              formatDateFromNowByMin(params.value),
            cellClass: "excelStyleID-dateUS",
          },
          {
            field: "start_date",
            headerName: "Start Date",
            ...STANDARD_WIDTHS.MD,

            valueGetter: (params: ValueGetterParams<CAWorkflowExecution, string>) => params.data?.start_time,
            valueFormatter: (params: ValueFormatterParams<CAWorkflowExecution, string>) => formatCellDate(params),
            cellClass: "excelStyleID-dateUS",
          },
        ],
      },
      {
        headerName: "First Event",
        children: [
          {
            field: "workflow_event_type_first",
            headerName: "Type",
            ...STANDARD_WIDTHS.MD,
            valueGetter: (params: ValueGetterParams<CAWorkflowExecution, string>) => {
              if (
                params.data?.state.version_history[
                  params.data?.state.version_history.length - 1
                ].data.source_row_name.startsWith("TIP")
              ) {
                return "CREATE";
              }
              return "EDIT";
            },
          },
        ],
      },
      {
        headerName: "Last Event",
        children: [
          {
            field: "workflow_event_type",
            headerName: "Type",

            ...STANDARD_WIDTHS.MD,
            valueGetter: (params: ValueGetterParams<CAWorkflowExecution, string>) =>
              params.data?.state.event_history[0].data.workflow_event_type,
          },
          {
            field: "last_event_time",
            headerName: "Time",

            ...STANDARD_WIDTHS.MD,
            valueFormatter: (params: ValueFormatterParams) => formatDateFromNowByMin(params.value),
            valueGetter: (params: ValueGetterParams<CAWorkflowExecution, string>) =>
              params.data?.state.event_history[0].time,
            cellClass: "excelStyleID-dateUS",
          },
          {
            field: "last_event_date",
            headerName: "Date",
            ...STANDARD_WIDTHS.MD,
            valueGetter: (params: ValueGetterParams<CAWorkflowExecution, string>) =>
              params.data?.state.event_history[0].time,
            valueFormatter: (params: ValueFormatterParams<CAWorkflowExecution, string>) => formatCellDate(params),
            cellClass: "excelStyleID-dateUS",
          },
          {
            field: "triggered_by",
            headerName: "Triggered By",

            ...STANDARD_WIDTHS.LG,
            valueGetter: (params: ValueGetterParams<CAWorkflowExecution, number>) => {
              if (params.data?.state.event_history[0].data.triggered_by.id) {
                return getDisplayNameForIdentity(params.data?.state.event_history[0].data.triggered_by);
              }
              return "";
            },
          },
          {
            field: "memo",
            headerName: "Memo",
            ...STANDARD_WIDTHS.LG,
            valueGetter: (params: ValueGetterParams<CAWorkflowExecution, string>) =>
              params.data?.state.event_history[0].data.memo,
          },
        ],
      },
      {
        headerName: "Approvals",
        children: [
          {
            field: "state.approval_status",
            headerName: "Status",
            ...STANDARD_WIDTHS.SM,
            minWidth: 55,
            cellRenderer: (params: GroupCellRendererParams) => {
              return (
                <WFExecIconStatus
                  value={params.value}
                  currentApprover={params.data.search_attributes?.current_approver}
                />
              );
            },
          },
          {
            field: "current_approver",
            ...STANDARD_WIDTHS.LG,
            headerName: "Assigned To",
            valueGetter: (params: ValueGetterParams<CAWorkflowExecution, number>) => {
              if (params.data?.search_attributes.current_approver) {
                return getDisplayNameForIdentity(params.data?.search_attributes.current_approver);
              }
              return "";
            },
          },
        ],
      },
      {
        headerName: "PO/REQ Line",
        children: [
          {
            headerName: "Record",
            children: [
              {
                field: "source_row_name",
                ...STANDARD_WIDTHS.LG,
                minWidth: 135,
                headerName: "Name",

                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, string>) =>
                  params.data?.state.version_history[0]?.data.source_row_name,
                cellRendererSelector: (params) => {
                  const link = buildSourceLink(params.data?.state.version_history[0].data.rows[0].source_metadata);
                  return {
                    component: () => {
                      return (
                        <LinkCell
                          link={link}
                          value={params.value}
                          tooltipActive="Go to source record"
                          tooltipDisable="No source record link available"
                        />
                      );
                    },
                  };
                },
              },
              {
                field: "source_line_id",
                ...STANDARD_WIDTHS.SM,
                headerName: "Line",
                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, string>) =>
                  params.data?.state.version_history[0]?.data.rows[0].source_metadata.source_line_id,
              },
            ],
          },
          {
            headerName: "Customer",
            children: [
              {
                field: "company_name",
                ...STANDARD_WIDTHS.MD,
                headerName: "Name",
                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, string>) => {
                  const companyId = params.data?.state.version_history[0].data.company_id;
                  const companyName = companies.find((company) => company.id === companyId)?.name;
                  return companyName || "Unknown";
                },
              },
              {
                field: "company_id",
                ...STANDARD_WIDTHS.MD,
                headerName: "Id",
                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, number>) =>
                  params.data?.state.version_history[0].data.company_id,
              },
            ],
          },
          {
            headerName: "Item",
            children: [
              {
                field: "item_name",
                headerName: "Name",
                ...STANDARD_WIDTHS.MD,
                cellRenderer: (params: ValueGetterParams) => {
                  const itemId = params.data?.state.version_history[0].data.item_id;
                  const item = items.find((item) => item.id === itemId);
                  if (!item) {
                    return "";
                  }
                  return (
                    <ItemNameCell
                      item_name={item.name}
                      item_name_prefix={item.source_metadata.item_name_prefix}
                      item_variant={item.variant}
                      item_id_for_link={itemId}
                    />
                  );
                },
                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, string>) => {
                  const itemId = params.data?.state.version_history[0].data.item_id;
                  const itemName = items.find((item) => item.id === itemId)?.name;
                  return itemName || "Unknown";
                },
              },
              {
                field: "item_revision",
                headerName: "Rev",
                ...STANDARD_WIDTHS.SM,
                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, string>) => {
                  const itemId = params.data?.state.version_history[0].data.item_id;
                  const itemRevision = items.find((item) => item.id === itemId)?.variant;
                  return itemRevision || "Unknown";
                },
              },

              {
                field: "item_weight_unit",
                headerName: "Unit Wt",
                ...STANDARD_WIDTHS.SM,
                valueFormatter: (params: ValueFormatterParams<CAWorkflowExecution, number>) => {
                  return params.value ? Math.round(params.value) : "";
                },
                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, number>) => {
                  const itemId = params.data?.state.version_history[0].data.item_id;
                  const item = items.find((item) => item.id === itemId);

                  return item ? item.weight.toFixed(3) : undefined;
                },
              },
              {
                field: "item_weight_total",
                headerName: "Total MT",
                ...STANDARD_WIDTHS.SM,
                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, number>) => {
                  const itemId = params.data?.state.version_history[0].data.item_id;
                  const item = items.find((item) => item.id === itemId);
                  const quantity = params.data?.state.version_history[0]?.data.rows[0].quantity;
                  if (item && quantity) {
                    const totalMt = (item.weight * quantity) / 1000;
                    return totalMt.toFixed(3);
                  } else {
                    return "";
                  }
                },
              },
              {
                field: "item_id",
                ...STANDARD_WIDTHS.MD,
                headerName: "Id",
                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, number>) =>
                  params.data?.state.version_history[0].data.item_id,
              },
            ],
          },
          {
            headerName: "Vendor",
            children: [
              {
                field: "vendor_name",
                ...STANDARD_WIDTHS.MD,
                headerName: "Name",
                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, string>) => {
                  const vendorId = params.data?.state.version_history[0].data.rows[0].state_transitions[0].to_state_id;
                  const vendorName = vendors.find((vendor) => vendor.id === vendorId)?.name;
                  return vendorName || "Unknown";
                },
                cellRenderer: (params: CustomCellRendererProps) => {
                  return <span data-testid="vendor-name">{params.value}</span>;
                },
              },
              {
                field: "vendor_id",
                ...STANDARD_WIDTHS.SM,
                headerName: "Id",
                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, number>) =>
                  params.data?.state.version_history[0].data.rows[0].state_transitions[0].to_state_id,
              },
              {
                field: "vendor_status",
                ...STANDARD_WIDTHS.LG,
                headerName: "Status",
                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, string>) => {
                  const valDefault = "Unknown";
                  const itemId = params.data?.state.version_history[0].data.item_id;
                  const item = items.find((item) => item.id === itemId);
                  if (!item) return valDefault;
                  const vendorId = params.data?.state.version_history[0].data.rows[0].state_transitions[0].to_state_id;
                  const itemVendorConfig = vendorId ? item.config.vendor[vendorId] : null;
                  if (!itemVendorConfig) return valDefault;
                  const val = getVendorApprovalStatus(itemVendorConfig);
                  return val || valDefault;
                },
              },
              {
                field: "vendor_country",
                ...STANDARD_WIDTHS.SM,
                headerName: "Country",
                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, string>) =>
                  params.data?.search_attributes.vendor_country,
              },
              {
                field: "item_lot_size",
                ...STANDARD_WIDTHS.SM,
                headerName: "Lot Size",
                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, string>) => {
                  const valDefault = 0;
                  const itemId = params.data?.state.version_history[0].data.item_id;
                  const item = items.find((item) => item.id === itemId);
                  if (!item) return valDefault;
                  const val = item.config.item_lot_size;
                  return val || valDefault;
                },
              },
              {
                field: "minimum_order_quantity",
                ...STANDARD_WIDTHS.SM,
                headerName: "Min Qty",
                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, string>) => {
                  const valDefault = 0;
                  const itemId = params.data?.state.version_history[0].data.item_id;
                  const item = items.find((item) => item.id === itemId);
                  if (!item) return valDefault;
                  const vendorId = params.data?.state.version_history[0].data.rows[0].state_transitions[0].to_state_id;
                  const itemVendorConfig = vendorId ? item.config.vendor[vendorId] : null;
                  if (!itemVendorConfig) return valDefault;
                  const val = itemVendorConfig.minimum_order_quantity;
                  return val || valDefault;
                },
              },
              {
                field: "max_weekly_item_capacity",
                ...STANDARD_WIDTHS.MD,
                headerName: "Max Wkly Cap.",
                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, string>) => {
                  const valDefault = "NA";
                  const itemId = params.data?.state.version_history[0].data.item_id;
                  const item = items.find((item) => item.id === itemId);
                  if (!item) return valDefault;
                  const vendorId = params.data?.state.version_history[0].data.rows[0].state_transitions[0].to_state_id;
                  const itemVendorConfig = vendorId ? item.config.vendor[vendorId] : null;
                  if (!itemVendorConfig) return valDefault;
                  const val = itemVendorConfig.max_weekly_item_capacity;
                  return val || valDefault;
                },
              },
            ],
          },
          {
            headerName: "Shipping",
            children: [
              {
                field: "shipping_method",
                ...STANDARD_WIDTHS.MD,

                headerName: "Method",
                valueGetter: (params) => params.data?.state.version_history[0].data.rows[0].shipping_method,
              },
            ],
          },
          {
            headerName: "Quantity",

            children: [
              {
                field: "quantity",
                ...STANDARD_WIDTHS.SM,
                headerName: "Current",
                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, number>) =>
                  params.data?.state.version_history[0]?.data.rows[0].quantity,
                cellRenderer: (params: CustomCellRendererProps) => (
                  <span data-testid="quantity-current">{params.value}</span>
                ),
              },
              {
                field: "quantity_original",
                ...STANDARD_WIDTHS.SM,
                headerName: "Orig",
                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, number>) => {
                  const versionHistory = params.data?.state.version_history;
                  return versionHistory && versionHistory.length > 0
                    ? versionHistory[versionHistory.length - 1].data.rows[0].quantity
                    : undefined;
                },
                cellRenderer: (params: CustomCellRendererProps) => (
                  <span data-testid="quantity-original">{params.value}</span>
                ),
              },
              {
                field: "quantity_delta",
                ...STANDARD_WIDTHS.SM,
                headerName: "Delta",
                valueFormatter: (params: ValueFormatterParams<CAWorkflowExecution, number>) =>
                  params.value ? formatDeltaNumeric(params.value) : "",
                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, number>) => {
                  const versionHistory = params.data?.state.version_history;
                  if (versionHistory && versionHistory.length > 1) {
                    const quantityOriginal = versionHistory[versionHistory.length - 1].data.rows[0].quantity;
                    const quantity = versionHistory[0].data.rows[0].quantity;
                    return quantity - quantityOriginal;
                  } else {
                    return 0;
                  }
                },
              },
            ],
          },
          {
            headerName: "Cargo Ready",
            children: [
              {
                field: "date_cargo_ready",
                ...STANDARD_WIDTHS.SM,
                headerName: "Current",
                valueFormatter: (params: ValueFormatterParams<CAWorkflowExecution, Date>) =>
                  params.value ? format(params.value, dateFormatShort) : "",
                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, Date>) => {
                  const versionHistory = params.data?.state.version_history;
                  if (versionHistory && versionHistory.length > 0) {
                    const shipCurrent = getDateObj(versionHistory[0].data.rows[0].state_transitions[1].at);
                    const cargoReady = subDays(
                      shipCurrent ? shipCurrent : versionHistory[0].data.rows[0].state_transitions[1].at,
                      DAYS_CARGO_READY_TO_SHIP,
                    );
                    return cargoReady;
                  } else {
                    return undefined;
                  }
                },
                cellRenderer: (params: CustomCellRendererProps) => (
                  <span data-testid="cargo-ready-current">{params.valueFormatted}</span>
                ),

                cellClass: "excelStyleID-dateUS",
              },
              {
                field: "date_cargo_ready_original",
                ...STANDARD_WIDTHS.SM,
                headerName: "Orig",
                valueFormatter: (params: ValueFormatterParams<CAWorkflowExecution, Date>) =>
                  params.value ? format(params.value, dateFormatShort) : "",
                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, Date>) => {
                  const versionHistory = params.data?.state.version_history;
                  if (versionHistory && versionHistory.length > 0) {
                    const shipOriginal = getDateObj(
                      versionHistory[versionHistory.length - 1].data.rows[0].state_transitions[1].at,
                    );
                    const cargoReadyOriginal = subDays(
                      shipOriginal
                        ? shipOriginal
                        : versionHistory[versionHistory.length - 1].data.rows[0].state_transitions[1].at,
                      DAYS_CARGO_READY_TO_SHIP,
                    );
                    return cargoReadyOriginal;
                  } else {
                    return undefined;
                  }
                },
                cellRenderer: (params: CustomCellRendererProps) => (
                  <span data-testid="cargo-ready-original">{params.valueFormatted}</span>
                ),

                cellClass: "excelStyleID-dateUS",
              },
              {
                field: "date_cargo_ready_delta",
                ...STANDARD_WIDTHS.SM,
                headerName: "Delta",
                valueFormatter: (params: ValueFormatterParams<CAWorkflowExecution, number>) =>
                  params.value ? formatDeltaNumeric(params.value) : "",
                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, number>) => {
                  const versionHistory = params.data?.state.version_history;
                  if (versionHistory && versionHistory.length > 1) {
                    const shipCurrent = getDateObj(versionHistory[0].data.rows[0].state_transitions[1].at);
                    const cargoReady = subDays(
                      shipCurrent ? shipCurrent : versionHistory[0].data.rows[0].state_transitions[1].at,
                      DAYS_CARGO_READY_TO_SHIP,
                    );
                    const shipOriginal = getDateObj(
                      versionHistory[versionHistory.length - 1].data.rows[0].state_transitions[1].at,
                    );
                    const cargoReadyOriginal = subDays(
                      shipOriginal
                        ? shipOriginal
                        : versionHistory[versionHistory.length - 1].data.rows[0].state_transitions[1].at,
                      DAYS_CARGO_READY_TO_SHIP,
                    );
                    return differenceInDays(cargoReady, cargoReadyOriginal);
                  } else {
                    return 0;
                  }
                },
              },
            ],
          },
          {
            headerName: "To Location",
            children: [
              {
                field: "date_at_location",
                ...STANDARD_WIDTHS.SM,
                headerName: "Current",
                valueFormatter: (params: ValueFormatterParams<CAWorkflowExecution, Date>) =>
                  params.value ? format(params.value, dateFormatShort) : "",
                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, Date>) =>
                  getDateObj(params.data?.state.version_history[0]?.data.rows[0].state_transitions[2].at),
                cellRenderer: (params: CustomCellRendererProps) => (
                  <span data-testid="to-location-current">{params.valueFormatted}</span>
                ),

                cellClass: "excelStyleID-dateUS",
              },
              {
                field: "date_at_location_original",
                ...STANDARD_WIDTHS.SM,
                headerName: "Orig",
                valueFormatter: (params: ValueFormatterParams<CAWorkflowExecution, Date>) =>
                  params.value ? format(params.value, dateFormatShort) : "",
                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, Date>) => {
                  const versionHistory = params.data?.state.version_history;
                  return versionHistory && versionHistory.length > 0
                    ? getDateObj(versionHistory[versionHistory.length - 1].data.rows[0].state_transitions[2].at)
                    : undefined;
                },
                cellRenderer: (params: CustomCellRendererProps) => (
                  <span data-testid="to-location-original">{params.valueFormatted}</span>
                ),

                cellClass: "excelStyleID-dateUS",
              },

              {
                field: "date_at_location_delta",
                ...STANDARD_WIDTHS.SM,
                headerName: "Delta",
                valueFormatter: (params: ValueFormatterParams<CAWorkflowExecution, number>) =>
                  params.value ? formatDeltaNumeric(params.value) : "",
                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, number>) => {
                  const versionHistory = params.data?.state.version_history;
                  if (versionHistory && versionHistory.length > 1) {
                    const toLocationOriginal =
                      versionHistory[versionHistory.length - 1].data.rows[0].state_transitions[2].at;
                    const toLocation = versionHistory[0].data.rows[0].state_transitions[2].at;
                    return differenceInDays(toLocation, toLocationOriginal);
                  } else {
                    return 0;
                  }
                },
              },
            ],
          },
          {
            headerName: "Lead Time Days",
            children: [
              {
                field: "lead_time_days_remaining",
                ...STANDARD_WIDTHS.MD,
                headerName: "Until Req'd",
                valueFormatter: (params: ValueFormatterParams<CAWorkflowExecution, number>) => {
                  return params.value !== null && params.value !== undefined ? formatDeltaNumeric(params.value) : "-";
                },
                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, number>) => {
                  const versionHistory = params.data?.state.version_history;
                  if (versionHistory && versionHistory.length > 0) {
                    const toVendor = getDateObj(versionHistory[0].data.rows[0].state_transitions[0].at);
                    if (toVendor === null || toVendor === undefined) return null;
                    return differenceInDays(toVendor, new Date());
                  }
                },
              },
              {
                field: "lead_time_days",
                ...STANDARD_WIDTHS.SM,
                headerName: "Total",
                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, string>) => {
                  const valDefault = "Unknown";
                  const itemId = params.data?.state.version_history[0].data.item_id;
                  const item = items.find((item) => item.id === itemId);
                  if (!item) return valDefault;
                  const vendorId = params.data?.state.version_history[0].data.rows[0].state_transitions[0].to_state_id;
                  const itemVendorConfig = vendorId ? item.config.vendor[vendorId] : null;
                  if (!itemVendorConfig) return valDefault;
                  const val = itemVendorConfig.vendor_time_days + itemVendorConfig.shipment_time_days;
                  return val || valDefault;
                },
              },
            ],
          },
          {
            headerName: "",
            children: [
              {
                field: "date_to_pcl",
                ...STANDARD_WIDTHS.SM,
                headerName: "To PCL",
                valueGetter: (params: ValueGetterParams<CAWorkflowExecution, Date>) => {
                  return getDateObj(params.data?.state.version_history[0]?.data.rows[0].source_metadata.date_to_pcl);
                },
                valueFormatter: (params: ValueFormatterParams<CAWorkflowExecution, Date>) =>
                  params.value ? format(params.value, dateFormatShort) : "",

                cellClass: "excelStyleID-dateUS",
              },
            ],
          },
        ],
      },
    ],
    [companies, items, vendors],
  );
  const onGridReady = useCallback((event: GridReadyEvent) => setGridApi(event.api), [setGridApi]);

  const defaultColDef: ColDef = {
    resizable: true,
    flex: 1,
    suppressHeaderMenuButton: true,
    suppressMovable: false,
  };

  const handleRowClick = (params: RowClickedEvent) => {
    const queryParams = queryString.parse(location.search);
    queryParams.workflow_id = params.data.workflow_id;
    queryParams.workflow_run_id = params.data.workflow_run_id;

    const newSearch = queryString.stringify(queryParams, { encode: false });
    navigate({ search: newSearch, pathname: location.pathname }, { replace: true });
  };

  useEffect(() => {
    if (!gridApi) return;

    if (isLoading) {
      gridApi.showLoadingOverlay();
    } else if (!rowData) {
      gridApi.showNoRowsOverlay();
      gridApi.updateGridOptions({
        rowData: [],
      });
    } else {
      gridApi.hideOverlay();
      gridApi.updateGridOptions({
        rowData,
        columnDefs,
      });
      applyColumnState();
    }
  }, [gridApi, columnDefs, rowData, isLoading]);

  const applyColumnState = () => {
    const currentState = gridApi?.getColumnState();
    const parsedQuery = queryString.parse(location.search);
    if (!parsedQuery["visible_columns"]) {
      const defaultHiddenColumns = [
        "workflow_type_name",
        "start_time",
        "workflow_event_type_first",
        "last_event_date",
        "company_name",
        "company_id",
        "item_revision",
        "item_weight_unit",
        "item_weight_total",
        "item_id",
        "vendor_id",
        "vendor_country",
        "item_lot_size",
        "minimum_order_quantity",
        "max_weekly_item_capacity",
        "quantity_original",
        "date_cargo_ready_original",
        "date_at_location_original",
        "date_to_pcl",
      ];

      gridApi?.setColumnsVisible(defaultHiddenColumns, false);
      return;
    }

    const visibleColumns = parsedQuery["visible_columns"]?.toString();
    currentState?.forEach((column) => {
      if (visibleColumns?.includes(column.colId)) {
        column.hide = false;
      } else {
        column.hide = true;
      }
    });

    gridApi?.applyColumnState({ state: currentState, applyOrder: false });
  };

  const currentQuery = queryString.parse(location.search);
  const setColumnVisibleState = (params: ColumnVisibleEvent) => {
    if (params.source === "api") {
      return;
    }

    gridApi?.closeToolPanel();
    const columnState = gridApi?.getColumnState();

    const visibleColumns = columnState?.filter((column) => {
      return !column.hide;
    });

    const visibleColumnIds = visibleColumns?.map((column) => {
      return column.colId;
    });

    const visibleColumnIdsString = visibleColumnIds || [];
    currentQuery["visible_columns"] = visibleColumnIdsString;
    const newQueryString = queryString.stringify(currentQuery, { arrayFormat: "comma" });

    navigate(`?${newQueryString}`, { replace: true });
  };

  useEffect(() => {
    if (gridApi) {
      gridApi.openToolPanel("columns");
    }
  }, [currentQuery["visible_columns"]]);

  const handleRowSelected = () => {
    if (!gridApi) return { isPossible: false, reason: SYNC_LINES_TOOLTIP_MSG_DISABLED_NONE_SELECTED };
    const selectedRowsArray = gridApi?.getSelectedRows();
    if (selectedRowsArray?.length === 0) {
      return setIsPossibleSyncData({ isPossible: false, reason: SYNC_LINES_TOOLTIP_MSG_DISABLED_NONE_SELECTED });
    }
    const allRowsApproved = selectedRowsArray.every((row) => row.state.approval_status === "APPROVED__SYNC_READY");
    return allRowsApproved
      ? setIsPossibleSyncData({ isPossible: true, reason: SYNC_LINES_TOOLTIP_MSG_ACTIVE })
      : setIsPossibleSyncData({
          isPossible: false,
          reason: SYNC_LINES_TOOLTIP_MSG_DISABLED_INVALID_SELECTION,
        });
  };

  return (
    <div
      className={cn("ag-theme-quartz w-full ag-height-workflow-page", { "pointer-events-none opacity-50": isSyncing })}
    >
      <AgGridReact
        suppressCellFocus
        suppressAutoSize
        reactiveCustomComponents
        rowSelection="multiple"
        suppressRowClickSelection={true}
        onCellClicked={(params) => {
          if (params.column.getColId() !== "source_row_name") {
            handleRowClick(params);
          }
        }}
        onRowSelected={handleRowSelected}
        loadingOverlayComponent={() => <ReloadIcon className="h-6 w-6 animate-spin" />}
        noRowsOverlayComponent={() => <div>No data available</div>}
        rowClass="group"
        {...{ defaultColDef, onGridReady }}
        onColumnVisible={setColumnVisibleState}
        sideBar={{
          toolPanels: [
            {
              id: "columns",
              labelDefault: "Columns",
              labelKey: "columns",
              iconKey: "columns",
              toolPanel: "agColumnsToolPanel",
              toolPanelParams: {
                suppressColumnMove: false, // Hides the ability to move columns
                suppressRowGroups: true, // Hides the row grouping section
                suppressValues: true, // Hides the values section
                suppressPivots: true, // Hides the pivot table section
                suppressPivotMode: true, // Hides the summary mode
                suppressColumnFilter: true, // Hides the column filter
                suppressColumnSelectAll: false, // Allows selecting/deselecting all columns
                suppressColumnExpandAll: false, // Allows expanding/collapsing all columns
                contractColumnSelection: false, // Initial state of column groups is collapsed
                suppressSyncLayoutWithGrid: false,
              },
            },
          ],
        }}
        excelStyles={[
          {
            id: "excelStyleID-dateUS",
            dataType: "DateTime",
            numberFormat: {
              format: "mm/dd/yyyy",
            },
          },
        ]}
      />
    </div>
  );
}
