import { useMemo } from "react";
import { Card } from "@androshq/shared-ui";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { measures } from "../../api/cube";
import BarChart from "../../components/cards/charts/BarChart";
import MetricsCard from "../../components/cards/metrics-card/MetricsCard";
import ForecastTable from "../../components/forecasting/ForecastTable";
import { PageActionBar, PageActionBarData } from "../../components/navigation/PageActionBar";
import { PageContent } from "../../components/navigation/PageContent";
import {
  SupportedGranularityOptions,
  buildDateRangeOutwardFromNextPeriodStart,
} from "../../components/tables/standards";
import { useCompanies } from "../../hooks/companies/useCompanies";
import { useItems } from "../../hooks/items/useItems";

export const ItemForecastingPage = () => {
  const navigate = useNavigate();
  const { id: itemId } = useParams<{ id: string }>();
  const { data: items } = useItems();
  const { data: companies } = useCompanies();

  const item = useMemo(() => _.find(items, { id: +itemId! }), [items, itemId]);
  const company = useMemo(() => _.find(companies, { id: item?.company_id }), [companies, item]);

  const companyItems = useMemo(
    () =>
      items
        ?.filter((item: { company_id: number }) => item.company_id === company?.id)
        .map((item: { id?: number; name: string }) => ({
          value: item.id?.toString() || "",
          label: item.name,
        })) || [],
    [items, company?.id],
  );

  const pageActionBarData: PageActionBarData = {
    filters: {
      comboboxes: [
        {
          value: itemId!,
          options: companyItems,
          onChange: (value) => {
            navigate({ pathname: `/sop/items/${value}/forecasting` });
          },
        },
      ],
      switches: [
        {
          data: [
            {
              label: "Demand Forecasting",
              href: `/sop/items/${itemId}/forecasting`,
            },
            {
              label: "Supply Planning",
              href: `/sop/items/${itemId}/supply-chain`,
            },
          ],
        },
      ],
    },
  };

  return (
    <>
      <PageActionBar
        className="flex items-center justify-between flex-wrap gap-3"
        pageActionBarData={pageActionBarData}
      />
      <PageContent className="grid gap-2.5">
        <Card className="grid grid-rows-[150px_1fr] overflow-auto">
          {item && company ? <ForecastTable {...{ item, company }} /> : null}
        </Card>

        <div className="grid grid-cols-1 gap-2.5 ">
          {item ? (
            <MetricsCard
              title="Current Forecast vs Historical Performance"
              granularity={SupportedGranularityOptions.week}
              granularityIsFixed={true}
              dateRange={buildDateRangeOutwardFromNextPeriodStart(12, 16, SupportedGranularityOptions.week)}
              dateRangeIsFixed={false}
              measures={[measures.actual.total, measures.forecast.leadTime.total, measures.forecast.mostRecent.total]}
              itemList={[item]}
              contentComponent={BarChart}
            />
          ) : null}
        </div>
      </PageContent>
    </>
  );
};
