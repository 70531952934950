import { useAuth } from "@androshq/auth/browser-client";
import { checkUserForPermissions } from "@androshq/auth/common";
import { Permission } from "@androshq/auth/common";
import { Separator } from "@androshq/shared-ui";
import { triggerGlobalSearchPopup } from "@androshq/sop/browser-client";
import { useModulesWithPermission } from "../hooks";
import Company from "./Company";
import ModuleSwitcher from "./ModuleSwitcher";
import Navigation from "./Navigation";
import UserAvatar from "./UserAvatar";

export default function Header() {
  const modulesWithPermissison = useModulesWithPermission();
  const hasModulesWithPermission = modulesWithPermissison.length > 0;
  const { user } = useAuth();
  const { allowed } = checkUserForPermissions(user, [
    Permission.APP_VIEWS_SEARCH,
  ]);

  return (
    <div className="border-border-input box-border flex h-10 items-center justify-between border-b py-2.5">
      <div className="flex items-center gap-5">
        <div className="flex h-10 items-center gap-3 rounded-md px-2.5 py-2">
          <Company />
          {hasModulesWithPermission && (
            <>
              <Separator orientation="vertical" />
              <ModuleSwitcher />
            </>
          )}
        </div>

        {<Navigation />}
      </div>
      <div
        className={`flex justify-end gap-20 lg:justify-between ${allowed ? "lg:w-1/2" : ""}`}
      >
        {allowed && (
          <div
            className="-ml-24 hidden items-center gap-5 lg:-ml-32 lg:flex"
            onClick={triggerGlobalSearchPopup}
          >
            <div className="w-full flex-1 md:w-auto md:flex-none">
              <button
                data-testid="search-btn"
                className="relative inline-flex h-7 w-full items-center justify-start whitespace-nowrap rounded-[0.5rem] border border-input bg-muted/20 px-4 py-2 text-sm font-normal text-muted-foreground shadow-none transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 sm:pr-12  md:w-48 lg:w-64"
              >
                <span className="inline-flex">Search items...</span>
                <span className="pointer-events-none absolute right-[0.3rem] top-[0.2rem] hidden h-5 select-none items-center gap-1 rounded border bg-muted/60 px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex">
                  <span className="text-xs">⌘</span>K
                </span>
              </button>
            </div>
          </div>
        )}

        <div className="flex items-center justify-end gap-5">
          {["dev", "staging"].includes(import.meta.env.ENVIRONMENT) && (
            <div className="text-red-800 bg-red-300 px-2 text-sm text-nowrap overflow-hidden w-20 md:w-auto">
              {import.meta.env.ENVIRONMENT} environment
            </div>
          )}
          <UserAvatar />
        </div>
      </div>
    </div>
  );
}
