import { useEffect, useMemo, useState } from "react";
import { Button, DownloadIcon, HeightIcon, Label } from "@androshq/shared-ui";
import { GridApi, GroupCellRendererParams, NewValueParams } from "ag-grid-community";
import { subDays } from "date-fns";
// import { measures } from "../../../api/cube";
import { ItemWithConfig } from "../../../api/types";
// import BarChart from "../../../components/cards/charts/BarChart";
// import MetricsCard from "../../../components/cards/metrics-card/MetricsCard";
import Tooltip from "../../../components/messages/Tooltip";
import VendorSummaryTable from "../../../components/tables/SupplyTopTable";
// import {
//   SupportedGranularityOptions,
//   buildDateRangeOutwardFromNextPeriodStart,
// } from "../../../components/tables/standards";
import { Switch } from "../../../components/ui/Switch";
import { useFetchStates } from "../../../hooks/states/useFetchStates";
import { sortAllRows } from "./helpers";
import { SupplyPlanningTableRow } from "./types";
import { getDefaultItemVendorConfig } from "../../../api/standards";

interface Props {
  item: ItemWithConfig;
  gridApi: GridApi | null;
  sortRowsAndUpdateEndingBalances: (
    params: GroupCellRendererParams | NewValueParams | GridApi,
    rows: SupplyPlanningTableRow[],
  ) => void;
}

export default function SupplyPlanningTopSection({ item, gridApi, sortRowsAndUpdateEndingBalances }: Props) {
  const { data: vendors } = useFetchStates({ state_type: "vendor" });
  // Finishing time days overrde
  const [finishingTimeDaysOverride, setFinishingTimeDaysOverride] = useState<number | null>(null);

  const mappedData = useMemo(() => {
    if (!item || !vendors) return;

    return (
      Object.keys(item.config.vendor).map((key) => {
        return {
          vendor: item.config.vendor[key],
          item: item.config,
        };
      }) || []
    );
  }, [item, vendors]);

  const updateAndReorderRows = () => {
    if (!gridApi) return null;

    const finishingTimeDays =
      finishingTimeDaysOverride === null ? item.config.finishing_time_days || 0 : finishingTimeDaysOverride;
    const defaultItemVendorConfig = getDefaultItemVendorConfig(item);
    const rowDataToSort: any[] = [];
    gridApi.forEachNode((node) => {
      let txnDate = node.data.txnDate;
      let dateRequired = node.data.dateRequired;
      if (node.data.type == "outbound") {
        txnDate = subDays(node.data.departLocationExpected, finishingTimeDays);
        dateRequired = subDays(txnDate, defaultItemVendorConfig.vendor_time_days + defaultItemVendorConfig.shipment_time_days);
      }
      rowDataToSort.push({ ...node.data, txnDate: txnDate, dateRequired: dateRequired });
    });
    const sortedRows = sortAllRows(rowDataToSort);
    gridApi.setGridOption("rowData", sortedRows);
    sortRowsAndUpdateEndingBalances(gridApi, sortedRows);
  };

  const exportTable = () => {
    gridApi?.exportDataAsExcel();
  };

  useEffect(() => {
    // Default this to null when changing item
    setFinishingTimeDaysOverride(null);
  }, [item]);

  useEffect(() => {
    updateAndReorderRows();
  }, [finishingTimeDaysOverride]);

  return (
    <div className="flex gap-2.5 justify-between flex-wrap h-36">
      <div className="flex-1 flex flex-col">
        <div className="flex-1 inline-flex items-end justify-between mx-6 mb-2">
          <Tooltip tooltip="Download .xlsx">
            {/* @ts-expect-error FIX in shared-ui */}
            <Button onClick={exportTable} className="flex items-center gap-1.5 text-xs" variant="outline" size="sm">
              <DownloadIcon />
              Export Table
            </Button>
          </Tooltip>
          <div className="inline-flex space-x-4 items-center">
            <Tooltip tooltip="Disabling buffer orders demand by Date Due" className="mr-4 text-xs">
              <div className="flex items-center space-x-2 text-xs">
                <Label htmlFor="buffer-toggle" className="text-xs">
                  Include {item.config.finishing_time_days} day buffer
                </Label>
                <Switch
                  id="buffer-toggle"
                  size="sm"
                  checked={finishingTimeDaysOverride === null}
                  onCheckedChange={(checked) => {
                    const newOverride = checked ? null : 0;
                    setFinishingTimeDaysOverride(newOverride);
                  }}
                />
              </div>
            </Tooltip>
            <Tooltip tooltip="Reorder based on Txn Date">
              <Button
                onClick={updateAndReorderRows}
                className="flex items-center gap-1.5 text-xs"
                /* @ts-expect-error FIX in shared-ui */
                variant="outline"
                size="sm"
              >
                <HeightIcon />
                Reorder Lines
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="flex-1 flex flex-col">
        <div className="borderborder-gray-200 shadow p-2 rounded-xl flex-[3]">
          <VendorSummaryTable rows={mappedData} isLoading={vendors ? false : true} />
        </div>
      </div>
      {/* <MetricsCard
        title="Current Forecast vs Historical Performance"
        granularity={SupportedGranularityOptions.month}
        granularityIsFixed={true}
        dateRange={buildDateRangeOutwardFromNextPeriodStart(6, 5, SupportedGranularityOptions.month)}
        dateRangeIsFixed={true}
        measures={[measures.actual.total, measures.forecast.leadTime.total, measures.forecast.mostRecent.total]}
        itemList={[item]}
        contentComponent={BarChart}
      /> */}
    </div>
  );
}
