import { User } from "oidc-client-ts";
import { OIDC_USER_PROFILE_KEY_ROLES } from "./constants";
import { Department, Permission, Role, rolePermissionsMap } from "./types_generated";

export const checkUserForPermissions = (
  user: User | null,
  requiredPermissions: Permission[],
  department?: Department,
  reasonOverride?: string,
): { allowed: boolean; reason?: string } => {
  if (!user) {
    console.warn("No user found while checking permissions... this should not happen");
    return { allowed: false, reason: reasonOverride || "No user found" };
  }
  const roles = getRolesFromUser(user);

  if (department) {
    const userInSpecifiedDepartment = checkRolesIncludeDepartment(user, department);
    if (!userInSpecifiedDepartment) {
      return { allowed: false, reason: reasonOverride || `Only a member of ${department} may perform this action.` };
    }
  }
  const userPermissions = mapRolesToPermissions(roles);

  const allowed = requiredPermissions.every((permission) => userPermissions.includes(permission));
  return { allowed, reason: reasonOverride || "User lacks necessary permissions to perform this action." };
};

export const checkRolesIncludeDepartment = (user: User, department: Department) => {
  // Check if user is e.g. PCL based on 2nd position of string role e.g. app:pcl:vn:manager would be true for PCL
  const userRoles = getRolesFromUser(user);
  return userRoles.some((role) => {
    const roleParts = role.split(":");
    if (roleParts.length < 2) {
      console.warn("Found invalid role structure while checking department... should have 4 parts...");
      return false;
    }

    if (!Object.values(Department).includes(roleParts[1] as Department)) {
      console.warn("Found invalid department in role structure while checking department...");
      return false;
    }
    const roleDept = roleParts[1] as Department;
    return roleDept === Department.ANY || roleDept === department;
  });
};

export const getRolesFromUser = (user: User): Role[] => {
  if (!user) return [];
  return Object.keys(user.profile[OIDC_USER_PROFILE_KEY_ROLES] || []) as Role[];
};

const mapRolesToPermissions = (roles: Role[]): Permission[] => {
  return roles.map((role) => rolePermissionsMap[role] || []).flat();
};
