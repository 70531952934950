interface Props {
  item_name: string;
  item_name_prefix?: string;
  item_variant?: string;
  extra_classes?: string;
  item_id_for_link?: string;
}

export default function ItemNameCell({ item_name, item_name_prefix, item_variant, extra_classes, item_id_for_link }: Props) {
  let item_page_path: string | undefined;
  if (item_id_for_link) {
    item_page_path = `/sop/items/${item_id_for_link}/supply-chain`;
  }
  return (
    <span className={extra_classes ? extra_classes : `${item_page_path ? "cursor-pointer hover:underline" : ""}`} onClick={() => item_page_path ? window.open(item_page_path, "_blank") : undefined}>
      <span className={extra_classes ? extra_classes : "text-muted-foreground"}>{item_name_prefix || " "}</span>
      <span data-testid="item-cell">{item_name} </span>
      {item_variant && <span className={extra_classes ? extra_classes : "text-muted-foreground"}>({item_variant})</span>}
    </span>
  );
}
